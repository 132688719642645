.root {
  font-family: theme('fontFamily.barlow');
  max-width: none;

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  blockquote {
    border-left-width: 0.5rem;
    max-width: theme('maxWidth.3xl');
    font-size: theme('fontSize.xl');
    line-height: theme('lineHeight.snug');
    font-weight: theme('fontWeight.normal');
    font-style: normal;

    @screen sm {
      font-size: theme('fontSize.2xl');
    }
  }

  table {
    font-size: theme('fontSize.base');

    td {
      padding-top: 0px;
      padding-bottom: 0px;
      white-space: nowrap;
    }
  }
}
