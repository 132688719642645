@import '@/styles/base/container';

.root {
  @extend .container;
  background: theme('colors.beige.light');
  position: relative;
  padding-top: 0;
  padding-bottom: 5rem;

  @screen md {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  h2 {
    margin-bottom: 1.5rem;
    font-family: theme('fontFamily.sangbleu');
    font-size: theme('fontSize.3xl');

    @screen sm {
      font-size: theme('fontSize.4xl');
    }
  }

  .wrapper {
    display: grid;
    gap: 2rem 3.5rem;

    @screen lg {
      grid-template-columns: repeat(3, 1fr);
    }

    > * {
      place-self: start;
    }

    .highlight {
      display: grid;
      gap: 3.5rem 1.25rem;
      width: 100%;

      @screen md {
        grid-template-columns: repeat(2, 1fr);
      }

      @screen lg {
        grid-column: span 2;
      }

      li {
        &[data-hide-on-mobile='true'] {
          display: none;

          @screen md {
            display: block;
          }
        }

        &:first-of-type {
          grid-column: 1 / -1;
        }

        .image div {
          position: relative;
          padding-bottom: 62%;
          overflow: hidden;

          img {
            object-fit: cover;
            transform: scale(1);
            transition: transform 0.45s ease;
          }
        }

        .content {
          margin-left: var(--container-space-x);
          position: relative;
          margin-top: -2rem;
          background: theme('colors.white');
          padding: 1.5rem;
          font-family: theme('fontFamily.grotesk');

          @screen md {
            margin-left: 2rem;
          }

          p {
            font-size: theme('fontSize.xl');
            margin-bottom: 1.25rem;

            span {
              &:not(:last-of-type) {
                margin-right: 0.75rem;
              }

              &:last-of-type {
                vertical-align: middle;
                margin: 0.25rem 0;
              }
            }
          }

          .date {
            font-size: theme('fontSize.xs');
          }

          ul {
            padding-top: 0.75rem;
            margin: -0.25rem;
            display: flex;
            flex-wrap: wrap;

            > * {
              margin: 0.25rem;
            }

            span {
              font-size: 10px !important;
              background: theme('colors.white');
              border: 1px solid theme('colors.beige.dark');
            }
          }
        }

        &:first-of-type {
          a {
            @screen md {
              display: grid;
              grid-template-columns: repeat(5, 1fr);
            }
          }

          .image {
            @screen md {
              grid-row-start: 1;
              grid-column: 2 / 6;
            }
          }

          .content {
            @screen md {
              grid-row-start: 1;
              grid-column: 1 / 3;
              position: relative;
              place-self: center;
              margin-left: unset;
            }
          }
        }

        &:hover,
        &:focus {
          .image img {
            transform: scale(1.05);
          }
        }
      }
    }

    .latest {
      width: 100%;
      display: flex;
      flex-direction: column;

      @screen md {
        display: none;
      }

      @screen lg {
        display: flex;
      }

      h3 {
        font-family: theme('fontFamily.grotesk');
        font-weight: theme('fontWeight.semibold');
        font-size: theme('fontSize.xl');
        margin-bottom: 1.5rem;
        display: none;

        @screen lg {
          display: block;
        }
      }

      ul {
        margin-bottom: 1.5rem;

        li {
          padding: 0.5rem 0;
          margin: 0 -0.75rem;
          border-bottom: 1px solid rgba(112 112 112 / 0.15);
          font-family: theme('fontFamily.grotesk');

          &[data-hide-on-mobile='true'] {
            display: none;

            @screen lg {
              display: block;
            }
          }

          &:first-child {
            padding-top: 0;
          }

          a {
            display: flex;
            align-items: center;
            padding: 0.5rem 0.75rem;
            border-radius: 2px;
            background: transparent;
            transition: background 0.25s ease;

            &:hover,
            &:focus {
              background: theme('colors.beige.dark');
            }

            p {
              font-size: theme('fontSize.sm');
              font-weight: theme('fontWeight.medium');
              margin-right: 1.5rem;
              flex-grow: 1;

              span {
                &:not(:last-of-type) {
                  margin-right: 0.75rem;
                }

                &:last-of-type {
                  vertical-align: middle;
                  margin: 0.25rem 0;
                }
              }
            }

            div {
              position: relative;
              max-width: 110px;
              width: 100%;

              > div {
                padding-bottom: 64%;

                img {
                  object-fit: cover;
                }
              }
            }
          }
        }
      }

      a {
        margin: auto;
      }
    }
  }

  > svg {
    transform: rotate(45deg);
    position: absolute;
    z-index: 0;
    top: -7.5%;
    left: -20%;
    width: 80vw;

    @screen sm {
      top: -7.5%;
      left: -20%;
      width: 60vw;
    }

    @screen lg {
      top: -20%;
      left: -10%;
    }

    @screen xl {
      top: -20%;
      left: -10%;
      width: 55vw;
    }

    @screen 2xl {
      width: 55%;
    }
  }
}
