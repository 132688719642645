.root {
  padding-bottom: 4rem;
  position: relative;
  z-index: 1;

  .slide {
    @screen md {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .image {
      position: relative;
      padding-bottom: 66.67%;
      overflow: hidden;
      background: theme('colors.yellow.base');

      @screen sm {
        padding-bottom: 56.25%;
      }

      @screen md {
        padding-bottom: 66.67%;
        grid-column: span 2;
      }

      img {
        object-fit: cover;
        transform: scale(1);
        transition: transform 0.45s ease;
      }

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        margin: 0;
      }
    }

    .content {
      margin-top: -4rem;
      position: relative;
      text-align: start;

      @screen md {
        grid-row: 1;
        grid-column: 1;
        margin-top: unset;
        display: flex;
        align-items: center;
      }

      &__wrapper {
        padding: 2rem;
        margin-top: 0.75rem;
        margin-left: var(--container-space-x);
        background-color: theme('colors.white');
        box-shadow: 20px 30px 30px rgba(0, 0, 0, 0.05);
        font-family: theme('fontFamily.grotesk');

        @screen md {
          margin-top: unset;
          margin-left: unset;
          margin-right: calc(var(--container-space-x) * -1);
        }

        .label {
          span {
            &:not(:last-of-type) {
              margin-right: 1rem;
            }

            &:last-of-type {
              vertical-align: middle;
            }
          }
        }

        span {
          text-transform: uppercase;
          font-size: theme('fontSize.xs');
          letter-spacing: theme('letterSpacing.widest');
        }

        .title {
          margin: 1rem 0 1.75rem 0;
          font-size: theme('fontSize.2xl');
          line-height: theme('lineHeight.tight');
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;

          @screen lg {
            font-size: theme('fontSize.3xl');
          }

          @screen xl {
            font-size: theme('fontSize.4xl');
          }
        }

        .read-more {
          text-transform: uppercase;
          font-size: theme('fontSize.sm');
          font-weight: theme('fontWeight.semibold');
          letter-spacing: theme('letterSpacing.widest');
          color: theme('colors.yellow.base');
        }
      }
    }

    &:hover,
    &:focus {
      .image img {
        transform: scale(1.05);
      }

      .read-more {
        text-decoration: underline;
      }
    }
  }
}

.controls {
  display: none;
  flex-wrap: wrap;
  margin: 0.5rem -0.125rem 0 var(--container-space-x);
  width: fit-content;

  @screen md {
    display: flex;
    margin-left: -0.125rem;
    position: relative;
    z-index: 1;
  }

  > * {
    margin: 0.25rem;
  }

  button {
    border-radius: 2px;
    background: transparent;
    transition: background 0.25s ease;

    &:hover,
    &:focus {
      background: theme('colors.beige.dark');
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
