@import '@/styles/base/container';

.root {
  @extend .container;
  background: rgba($color: #dfd6c9, $alpha: 0.3);
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
  gap: 2rem;

  @screen sm {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  @screen md {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  @screen lg {
    grid-template-columns: repeat(2, 1fr);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    background: theme('colors.white');
    padding: 2rem 1rem;
    width: 100%;
    max-width: theme('maxWidth.2xl');
    margin: auto;

    @screen sm {
      padding: 3.5rem;
    }

    @screen lg {
      max-width: none;
      margin: 0;
    }

    h2 {
      font-family: theme('fontFamily.sangbleu');
      font-size: theme('fontSize.3xl');
      margin-bottom: 1.5rem;

      @screen sm {
        font-size: theme('fontSize.4xl');
      }
    }

    ul {
      flex-grow: 1;
      display: grid;
      margin: -0.75rem;

      li {
        margin: 0.375rem;

        .card {
          display: grid;
          gap: 1.5rem;
          grid-template-columns: auto 1fr;
          align-items: center;
          padding: 0.375rem;
          border-radius: 2px;
          background: transparent;
          transition: background 0.25s ease;

          &:hover,
          &:focus {
            background: theme('colors.beige.dark');
          }

          .media {
            width: 80px;
            height: 52px;
            background: theme('colors.yellow.highlight');
            position: relative;

            @screen sm {
              width: 110px;
              height: 70px;
            }

            img {
              object-fit: cover;
            }

            svg {
              width: 1.5rem;
              height: 1.5rem;
              fill: theme('colors.white');
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .meta {
            p {
              font-family: theme('fontFamily.grotesk');

              &:first-of-type {
                font-family: theme('fontFamily.sangbleu');
                font-size: theme('fontSize.2xl');
                font-style: italic;
                margin-bottom: 0.25rem;

                &::first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }

    > a {
      align-self: start;
      margin-top: 2.5rem;
    }
  }
}
