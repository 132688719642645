@import '@/styles/base/container';

.root {
  @extend .container;
  background: theme('colors.beige.light');
  padding-top: 5rem;
  padding-bottom: 5rem;

  @screen md {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  h2 {
    margin-bottom: 1.5rem;
    font-family: theme('fontFamily.sangbleu');
    text-align: center;
    font-size: theme('fontSize.3xl');

    @screen sm {
      font-size: theme('fontSize.4xl');
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: theme('maxWidth.screen-lg');
    margin: auto;

    li {
      margin: 0.5rem;
      width: 104px;

      @screen md {
        width: 164px;
      }

      a {
        div {
          color: theme('colors.white');
          background: theme('colors.yellow.highlight');
          padding: 1rem 2rem;
          display: grid;
          place-content: center;

          @screen sm {
            padding: 1.5rem 3rem;
          }

          @screen md {
            padding: 2rem 4rem;
          }

          svg {
            width: 2rem;
            height: 2rem;
          }
        }

        p {
          font-family: theme('fontFamily.grotesk');
          text-align: center;
          margin-top: 0.75rem;
        }

        &:hover,
        &:focus {
          p {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
