@import '@/styles/base/container';

.root {
  @extend .container;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: theme('colors.beige.light');

  @screen sm {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  p {
    font-family: theme('fontFamily.grotesk');
  }

  .buttons {
    display: none;
    flex-wrap: wrap;
    margin: -0.5rem;

    @screen sm {
      display: flex;
    }

    > * {
      margin: 0.5rem;
    }
  }
}
