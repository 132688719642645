@import '@/styles/base/container';

.root {
  @extend .container;
  font-family: theme('fontFamily.grotesk');
  background: theme('colors.beige.light');
  display: grid;
  gap: 2.5rem;
  align-items: center;
  overflow: hidden;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @screen md {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @screen lg {
    gap: 2.5rem 5rem;
  }

  .person {
    font-size: theme('fontSize.sm');
    position: relative;
    height: fit-content;
    width: 100%;
    max-width: theme('maxWidth.lg');
    margin-left: auto;

    @screen xl {
      max-width: theme('maxWidth.xl');
    }

    .image {
      z-index: 1;
      position: relative;
      margin-left: auto;
      width: 75%;

      @screen md {
        z-index: 0;
      }

      @screen md {
        position: static;
      }

      &__main {
        padding-bottom: 100%;
        background: theme('colors.beige.dark');
        position: relative;

        img {
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .card {
      z-index: 2;
      position: relative;
      background: theme('colors.white');
      padding: 1rem;
      width: fit-content;
      max-width: 260px;
      margin-top: -4rem;

      @screen lg {
        padding: 1.5rem;
        margin-top: 0;
        position: absolute;
        left: 0;
        bottom: 2.5rem;
      }

      p:first-of-type {
        font-weight: theme('fontWeight.semibold');
      }

      p:last-of-type {
        font-weight: theme('fontWeight.light');
      }
    }

    svg {
      z-index: 1;
      position: absolute;
      transform: rotate(270deg);
      pointer-events: none;
      width: 1000px;
      top: 0;
      left: -100vw;

      @screen sm {
        width: 1260px;
      }

      @screen md {
        width: 100vw;
        left: -150%;
      }

      @screen lg {
        max-width: 1200px;
        left: -150%;
      }
    }
  }

  .content {
    position: relative;
    z-index: 1;
    max-width: theme('maxWidth.lg');

    @screen lg {
      max-width: theme('maxWidth.md');
    }

    > span {
      background: theme('colors.yellow.base');
      color: theme('colors.white');
      font-weight: theme('fontWeight.semibold');
    }

    h2 {
      margin-top: 1.25rem;
      font-family: theme('fontFamily.sangbleu');
      font-size: theme('fontSize.3xl');

      @screen sm {
        font-size: theme('fontSize.4xl');
      }
    }

    p {
      margin-top: 1.25rem;
    }

    > a {
      margin-top: 1.25rem;
    }
  }
}
